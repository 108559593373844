@import url('https://gitcdn.github.io/bootstrap-toggle/2.2.2/css/bootstrap-toggle.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;1,100&family=Smooch&display=swap');

/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;1,100&display=swap'); */
@import '~antd/dist/antd.css';
body{
  font-family: 'Poppins', sans-serif!important;
}
.react-datetime-picker__inputGroup__input{
    width:18px !important
}
.react-datetime-picker__inputGroup__input--hasLeadingZero{
    width:10px !important
}
.react-datetime-picker__inputGroup__year{
    width:40px !important
}
.react-datetime-picker__inputGroup__leadingZero{
    margin-left: 2px !important;
}
.tableWinner{
    overflow: scroll !important;
    width:100% !important;
    min-height: '75vh';
    padding: '10px';
    background: white
}
.rightPannel{
    margin-left: 250px;
}
.ant-popover{
    width: 50%
}
.overlayy {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(242, 236, 236, 0.577);
  /* z-index: 99; */
  z-index: 9999!important;
}
.overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.overlay__content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  width: 75px;
  height: 75px;
  display: inline-block;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.35);
  border-top-color: #0476a7;
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
}
  
@media screen and (max-width: 767px) {
    .rightPannel{
        margin-left: 75px!important;
    }
  }
Table{
  background-color:#333439;
  color: aliceblue;
}
.ant-page-header-heading-title{
  color: #f0e4b2;
}
.ant-table-content {
 border-radius: 0.7rem;
 }
.ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td, 
.ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td, 
.ant-table-thead>tr:hover:not(.ant-table-expanded-row)>td, 
.ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td {
    background: white;
    color: black;
}
td.ant-table-column-sort {
  background: #333439!important;
}
.ant-table-container{
  background-color: #212224 !important;
  border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
.ant-modal-header{
  background: #212224;
  border-radius: 17px 17px 0px 0px !important;
}
.ant-modal-content{
  border-radius: 17px 17px 17px 17px !important;
}
.ant-modal {
  /* max-width: 250px; */
  border-radius: 17px;
  padding: 1rem;
  position: relative;
  background: linear-gradient(to right, rgb(174 132 30), #f0e4b2);
  padding: 3px;
}
.ant-btn-primary{
  /* background: #00DFDF!important; */
  background-color: rgb(174 132 30) !important;
  color: white!important;
  border-color:rgb(174 132 30) !important; 
}
.ant-btn-primary:hover{
  background-color: rgb(139, 108, 27) !important;
  color: white!important;
  border-color:rgb(174 132 30) !important; 
}
.btn1{
  background-image: linear-gradient(to bottom right,rgb(174 132 30), rgb(174 132 30)) !important;
}
.ant-modal-title{
  color: aliceblue;
}
.ant-modal-close-icon{
  color: aliceblue;
}
.ant-btn{
  padding:5px 15px
}
.ant-menu-item-selected {
  background-color: rgb(174 132 30) !important;
  color:white!important;
  border-radius: 0 13rem 13rem 0;
}
.ant-menu-item:hover {
  color: white !important;
}
.divi{
  background: rgb(174 132 30)!important;
}
.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4D4D4D;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19AB27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128D15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4D4D4D;
  border-radius: 50%;
  background-color: #FAFAFA;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19AB27;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
  -moz-box-shadow: 0px 0px 3px 2px #0099E0;
  box-shadow: 0px 0px 2px 3px #0099E0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
  -moz-box-shadow: 0px 0px 5px 5px #0099E0;
  box-shadow: 0px 0px 5px 5px #0099E0;
}
.connection_image{
  height: 100px;
  width: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border: 1px solid #000000
}
.site-collapse-custom-collapse .site-collapse-custom-panel {
  margin-bottom: 24px;
  overflow: hidden;
  background: #333439;
  border: 0px;
  color: #FAFAFA!important;
  border-radius: 2px;
}
.ant-collapse-borderless {
  background-color: #212224!important;
  color: #FAFAFA!important;
  border: 0;
}
.ant-collapse{
  color: #FAFAFA!important;
  background-color: #212224!important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header{
  color: #FAFAFA!important;
}
.ant-collapse-content{
  color: #FAFAFA;
}
.btn_question_submit{
  color: black;
  background: rgb(174 132 30);
}
.jodit-wysiwyg{
  background: #FAFAFA!important;
  height: 60vh!important;
}
.ant-page-header-back-button{
  color: rgb(174 132 30)!important;
}
.ant-radio-button{
  background: rgb(174 132 30)!important;
}
.ant-table-tbody > tr.ant-table-row-selected > td{
  background-color: #212224!important;
  color: white!important;
}
.ant-table-cell-row-hover {
  color: black;
}
.ant-tabs-tab{
  color: white;
}
.ant-menu-inline .ant-menu-item::after{
  border-right : none
}
.para{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

   line-height: 16px; /* Height taken by one line */
   max-height: 32px;
}
.select_file{
  /* padding: 10px; */
  background: rgb(174 132 30); 
  display: table;
  color: #fff;
   }

input[type="file"] {
  display: none;
}

.workout_div .jodit-container{
height: 50px!important;
}

.white__placeholder::placeholder{
  color: #fff;
}
.ant-empty-description{
  color:#a9861d!important
}

.addVideoModel .ant-table-tbody > tr > td{
  padding:0px 0px !important
}

.addVideoModel  .ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td{
  background-color: transparent !important;
}
/* .ant-pagination-prev, .ant-pagination-next {
  color: white !important;
} */

.ant-pagination.ant-pagination-mini .ant-pagination-item{
  background-color : white!important;
  /* background-color : rgb(189, 179, 179) !important; */
}
.ant-pagination-item-active a {
  background-color: #AE841E !important;
  border : 1px solid #AE841E !important;
  border-color: #AE841E !important;
/* promo code */
}
.ant-picker-content {
background: white !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}
.promoCodeBtn{
  background: rgb(174, 132, 30);
  width: 20%;
  border-radius: 8px;
  height: 38px;
  color: white;
}
.promoCodeBtn{
  color: white !important;
}
.validFor{
  color: white;
}
.validFor:hover{
  color: #000000;
}
.promoCde.ant-modal-body{
  background-color: #333439;
}